html,body{
  scroll-behavior: smooth;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

hr{
  border-top-width: 2px;
  border-color: lightgrey;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


@media only screen and (max-width: 1250px){
  .header3949001Item{
    width: unset!important;
    margin-left: unset!important;
  }
  .bestMemeContainer, .sidebarFeatured{
    width: unset!important;
    margin-left: unset!important;
  }
}

@media only screen and (max-width: 1023px){
  .container-BEST{
    max-width: 100%!important;
    padding-left: 0px!important;
    padding-right: 0px!important;
  }
}

.doggyMobile{
  display: none;
}

.listlink{
  color: blue;
  text-decoration: underline;
}

@media only screen and (max-width: 810px){
  .doggyDesktop{
    display: none;
    overflow: hidden;
  }
  .doggybutton{
    margin-top: 40px!important
  }
  .poodlanabutton{
    margin-top: 40px!important
  }
  .doggyMobile{
    display: block;
    overflow: hidden;
    position: absolute;
    width: 300px!important;
  }
  .doggyMobilePlaceholder{
    height: 250px;
  }
}

@media only screen and (max-width: 330px){
  .doggyMobile{
    margin-left: -50px;
  }
}

@media only screen and (max-width: 768px){
  .header981390139050Item{
    display: none!important;
  }
  .header3949001Item h2{width: 100%!important;}
}